<template>
  <div class="page_msg container">
    <div class="header_gruop page_width flex_sb">
      <div class="icon_home flex_center" @click="toBack()"><svg t="1729563443244" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6080" id="mx_n_1729563443245" width="16" height="16"><path d="M398.912 513.152l377.28-384.256c27.904-28.416 27.904-74.432 0-102.784-27.84-28.352-73.024-28.352-100.8 0L247.808 461.504C233.792 475.776 226.88 494.464 226.944 513.152 226.88 531.84 233.792 550.464 247.808 564.736l427.584 435.392c27.84 28.352 73.024 28.352 100.8 0 27.904-28.352 27.904-74.368 0-102.656L398.912 513.152z" fill="#16181c" p-id="6081"></path></svg></div>
      <div class="header_title">Terms of Use</div>
      <div></div>
    </div>

    <div class="scoller grid_content" :v-infinite-scroll="false" style="overflow:auto">
        <div class="service page_width">
            <p>Terms of Use</p>
            <p> <strong>Welcome to the playsavefirst Platform</strong> </p>
            <p>Thank you for visiting playsavefirst.net (the “Site”) and/or the playsavefirst mobile app (the “Application”) (collectively, the “Platform”). playsavefirst is an online marketplace designed to facilitate the purchase and sale of digital goods and services, including but not limited to games, gift cards, and game-related products through verified merchants.
              By creating an account and accessing the Platform, you agree to comply with these Terms of Use as well as any specific terms, conditions, and guidelines relevant to individual products or services offered through the Platform.</p>
    

            <p>Modification of Terms</p>
            <p>We reserve the right to modify or update these Terms of Use at any time. Any changes to these Terms will be posted on the Platform and, if necessary, communicated to you via email. Your continued access to and use of the Platform after such updates will constitute your acceptance of the revised Terms. Please check the Terms regularly for updates.</p>
            <p>1. Definitions</p>
            <p>Account: Refers to the registered user account created on the Platform to access Services.</p>
            <p>Buyer: The individual purchasing items on the Platform.</p>
            <p>Gift Card: An electronic card purchased on the Platform containing stored value redeemable on the Platform.</p>
            <p>CD Key: An electronic code used to activate a game.</p>
            <p>Content: All information, data, text, images, videos, and materials available on the Platform.</p>
            <p>Intellectual Property: Includes copyrights, trademarks, and other proprietary rights held by their respective owners or by playsavefirst.</p>
            <p>Platform: Refers collectively to the playsavefirst website, Android app, iOS app, and any affiliated web services.</p>
            <p>Purchased Items: Items listed and bought by the Buyer on the Platform.</p>
            <p>Credits: Digital credits acquired by Buyers for use in purchasing goods and services on the Platform.</p>
            <p>Points: Points awarded for activities on the Platform, as defined by playsavefirst.</p>
            <p>Service(s): Refers to the services provided on the Platform, including digital goods sales and any content or resources available via the Platform.</p>
            <p>Service(s): Refers to the services provided on the Platform, including digital goods sales and any content or resources available via the Platform.</p>
            <p>User ID: The unique identifier assigned to your account upon registration.</p>
            <p>2. License of Use</p>
            <p>Upon your acceptance of and adherence to these Terms of Use, playsavefirst grants you a non-exclusive, non-transferable license to access and use the Platform and Services as described herein.</p>
            <p>3. Representations and Warranties</p>
            <p>Each time you use the Platform, you represent and warrant that you are of legal age. If you are a minor, playsavefirst assumes you have obtained consent from a parent or guardian who agrees to these Terms on your behalf.</p>
            <p>You agree to keep your account information secure, refrain from disclosing your password to unauthorized third parties, and promptly notify playsavefirst of any security breaches.
              You agree not to:</p>
            <p>Misrepresent your identity or affiliation on the Platform.</p>
            <p>Use the Platform for any illegal, unauthorized, or harmful activities.</p>
            <p>Use playsavefirst’s intellectual property without prior written consent.</p>
            <p>Engage in disruptive, harmful, or disrespectful behavior towards other users or playsavefirst personnel.</p>
            <p>Attempt to hack or circumvent security features on the Platform.</p>
            <p>Collect or harvest information from other users without their consent.</p>
            <p>4. Account ID</p>
            <p>To access certain Platform features, you may need to create an account by providing accurate personal information. You agree to update your information as necessary to keep it current. If any inaccurate information is provided, playsavefirst reserves the right to suspend or terminate your account.</p>
            <p>5. Use of the Services</p>
            <p>You are responsible for securing and maintaining any hardware, software, or other tools needed to access the Platform. You are solely responsible for understanding and adhering to the policies governing the use of Services provided by playsavefirst</p>
            <p>You acknowledge that any actions taken via your account will be considered as requested by you. playsavefirst is not responsible for verifying the authority of the account user or the authenticity of such requests.</p>
            <p>6. Goods and Services</p>
            <p>All items on the Platform are presented on a non-exclusive, revocable basis. Points or credits reflected in your account have no real-world monetary value and cannot be redeemed for cash.</p>
            <p>7. Availability of Services</p>
            <p>playsavefirst may introduce new or beta features that may not perform as expected. </p>
            <p>playsavefirst may also require identity verification to ensure security and compliance.</p>
            <p>Accounts inactive for a continuous 12-month period may be subject to a service fee.</p>
            <p>8. Disclaimer of Liability</p>
            <p>playsavefirst provides the Platform “as-is” and “as available” without any warranties, express or implied, including but not limited to fitness for a particular purpose. To the fullest extent permissible by law, playsavefirst disclaims all liability for any indirect, consequential, or incidental damages arising from the use of the Platform or Services.</p>
            <p>9. Intellectual Property Rights</p>
            <p>All proprietary content on the Platform is the exclusive property of playsavefirst or respective third parties. No rights are granted to you to use, reproduce, or distribute such content without permission.</p>
            <p>Any submissions you make may be used by playsavefirst without obligation or compensation to you. By submitting content, you grant playsavefirst a perpetual, non-exclusive, royalty-free license to use, distribute, and modify your content for any purpose.</p>
            <p>10. Platform Reliability</p>
            <p>You acknowledge that network and telecommunications issues may affect your ability to access the Platform. playsavefirst is not responsible for delays or errors in notifications or confirmations sent via the Platform.</p>
            <p>11. Account Security</p>
            <p>You agree to promptly notify playsavefirst of any unauthorized access or use of your account. You are responsible for all actions taken through your account prior to such notice.</p>
            <p>12. Disclosure of Information</p>
            <p>playsavefirst reserves the right to disclose information related to your account if required by law or as deemed necessary to enforce these Terms of Use.</p>
            <p>13. Indemnification</p>
            <p>You agree to indemnify and hold playsavefirst and its affiliates harmless from any claims arising out of your breach of these Terms, your misuse of the Platform, or your violation of any third-party rights.</p>
            <p>14. Account Suspension or Termination</p>
            <p>playsavefirst reserves the right to suspend or terminate your account at any time for reasons including, but not limited to, suspected fraud, unauthorized account sharing, or violations of these Terms of Use.</p>
            <p>If you wish to deactivate your account, you may contact playsavefirst to do so.</p>
            <p>These Terms and Conditions shall be governed by and construed in accordance with law of HK.</p>
            <p>Navigator (Hong Kong) Commercial Service Co., Limited</p>
            <p>ROOM 2103 FUTURA PLAZA, 111 HOW MING STREET,  KWUN TONG, KL</p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>

        </div>
    </div>
  </div>
</template>

<script>
export default {
    methods:{
        toBack(){
            this.$router.back()
        }
    }
}
</script>

<style>

</style>